export const csear = {
  data() {
    return {
      seasonPassStatuses: [
        {
          value: "pending-payment",
          label: "Pending Payment",
          color: "warning",
        },
        { value: "active", label: "Active", color: "success" },
        {
          value: "payment-declined",
          label: "Payment Declined",
          color: "error",
        },
        { value: "payment-issue", label: "Payment Issue", color: "error" },
      ],
    };
  },

  methods: {
    getSeasonPassStatusLabel(value) {
      const status = this.seasonPassStatuses.find((s) => s.value === value);

      return status.label;
    },

    getSeasonPassStatusColor(value) {
      const status = this.seasonPassStatuses.find((s) => s.value === value);

      return status.color;
    },
  },
};
